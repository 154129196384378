import { reactive, computed } from 'vue'

export const state = reactive({
  toast: null,
})

export const toast = computed(() => state.toast)

export const handleToast = (toast) => {
  state.toast = toast
  setTimeout(() => {
    state.toast = null
  }, 10000)
}
